import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CandidatCard from '../../../components/organisms/CandidatCard/CandidatCard';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import ManagerConsultantBeneficiaryAttribute from '../../manager/ManagerConsultantBeneficiaryAttribute/ManagerConsultantBeneficiaryAttribute';
import { selectors as WSSelectors } from '../../../redux/WSRedux';
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../../redux/ConsultantRedux';
import EmptyCard from '../../../components/molecules/EmptyCard/EmptyCard'

import './BeneficiaryList.scss';
import history from 'history';
const financerState = {
  to_fill: 'to_fill',
  pending: 'pending',
  done: 'done',
};


const BeneficiaryList = ({ onDeleteClick, beneficiaries, isPrimary }) => {
  const dispatch = useDispatch();
  const [beneficiary, setBeneficiary] = useState(null);
  const [consultant, setConsultant] = useState(null);
  const [popinOpen, setPopinOpen] = useState(false);
  const user = useSelector(UserSelectors.user);
  const pending = useSelector((state) => WSSelectors.pending(state, 'beneficiary.attribute'));
  const consultants = useSelector(ConsultantSelectors.consultants);
  const pathname = useSelector(NavigationSelectors.pathname);

  useEffect(() => {
    dispatch(ConsultantActions.getConsultants());
  }, [dispatch]);

  const redirect = (destination) => {
    dispatch(NavigationActions.push(destination));
  };

  const checkAccompanimentType = (type) => {
    let label;

    switch (type) {
      case 'appraisal':
        label = 'Bilan de compétences';
        break;
      case 'outplacement_individual':
        label = 'Outplacement Individuel';
        break;
      case 'vae':
        label = 'VAE';
        break;
      case 'coaching':
        label = 'Coaching';
        break;
      case 'orientation_review':
        label = 'Bilan orientation';
        break;
      case 'business_creation':
        label = "Création d'entreprise";
        break;
      case 'collective_scheme':
        label = 'Dispositif collectif';
        break;
      case 'outplacement_group':
        label = 'Outplacement Collectif';
        break;
      case 'formation':
        label = 'Formation';
        break;
      default:
        label = 'Autre';
    }
    return label;
  };

  const checkFinancerState = (financer_state) => {
    let color;
    let label;
    switch (financer_state) {
      case financerState.to_fill:
        label = 'A renseigner';
        color = 'red1';
        break;
      case financerState.pending:
        label = 'En cours de financement';
        color = 'orange1';
        break;
      case financerState.done:
      default:
        label = 'Validé';
        color = 'green1';
    }

    return {
      label,
      color,
    };
  };

  const handleBeneficiaryClick = (id) => {
    if (user.role === 'consultant' || user.role === 'manager') redirect(pathname.replace(/\w+$/, `${id}/courses`));
  };

  const handleNotificationsClick = () => {
    redirect('/consultant/messages?tab=notifications');
  };

  const handleMessagesClick = () => {
    redirect('/consultant/messages');
  };

  const handleDeleteClick = ({ id }) => {
    onDeleteClick({ id });
  };

  const handleSelectBeneficiary = (value) => {
    setPopinOpen(true);

    const selectedId = value && Number(value.id);
    const _beneficiary = value ? beneficiaries.find(({ id }) => id === selectedId) : null;
    const primary = _beneficiary
      ? _beneficiary.consultants.find(({ is_primary }) => is_primary)
      : null;

    const _consultant = primary ? consultants.find(({ id }) => id === primary.id) : null;

    const primaryConsultant = _consultant || primary;

    if (primaryConsultant && !primaryConsultant.beneficiaries) {
      primaryConsultant.beneficiaries = [];
    }

    setBeneficiary(_beneficiary);
    setConsultant(primaryConsultant);
  };

  const renderAttributePopin = () => {
    const close = () => {
      setPopinOpen(false);
    };

    return (
      <ManagerConsultantBeneficiaryAttribute
        isOpen={popinOpen}
        beneficiary={beneficiary}
        _consultant={consultant}
        onClose={close}
        pending={pending}
      />
    );
  };

  const renderBeneficiaries = () => {
    const locale = {
      financeStateLabel: 'Etat de financement',
      lengthLabel: 'Durée',
      reasonLabel: 'Raison',
      typeOfAccompanimentLabel: "Type d'accompagnement",
      programFollowedLabel: 'Parcours suivi',
      nextAppointmentLabel: 'Prochain RDV',
      groupLabel: 'Groupe',
    }; 

    const cards = beneficiaries.map((beneficiary, i) => {
      const {
        status,
        first_name,
        last_name,
        email,
        phone_mobile,
        image_url,
        accompaniment_duration,
        financer_state,
        accompaniment_type,
        phone_home,
        archived_reason = null,
        id,
        next_rdv,
        course,
        count_notifications,
        count_messages,
      } = beneficiary;

      const courseLength =
        Number.parseInt(accompaniment_duration, 10) > 1
          ? `${Number.parseInt(accompaniment_duration, 10)} heures`
          : `${Number.parseInt(accompaniment_duration, 10)} heure`;
      const typeOfAccompaniment = checkAccompanimentType(accompaniment_type);
      const financerState = checkFinancerState(financer_state);
      const primaryConsultant = beneficiary.consultants.find(({ is_primary }) => is_primary);

      let label_status = '';
      if (status === 'created') label_status = 'Prospect';
      else if (status === 'wait_financer') label_status = 'En attente de financement';
      else if (status === 'in_validation') label_status = 'En attente de validation';
      else if (status === 'in_progress') label_status = 'En cours';
      else if (status === 'follow_up') label_status = 'Suivi professionnel';
      else if (status === 'archived') label_status = 'Historique';

      let reason_of_archived = '';
      if (archived_reason === 'completed') reason_of_archived = 'Accompagnement terminée';
      else if (archived_reason === 'beneficiary_abandonment')
        reason_of_archived = 'Abandon du talent';
      else if (archived_reason === 'budget_not_accepted') reason_of_archived = 'Budget non accepté';
      else if (archived_reason === 'beneficiary_refusal') reason_of_archived = 'Refus talent';
      else reason_of_archived = 'Autre';
      return (
        <CandidatCard
          key={i}
          id={id}
          status={{ status, label: label_status }}
          name={`${first_name} ${last_name}`}
          email={email}
          phone={phone_mobile || phone_home || ''}
          avatar={image_url || ''}
          locale={locale}
          color={financerState.color}
          length={courseLength}
          accompaniment_type={typeOfAccompaniment}
          financer_state={financerState.label}
          percentage={course && course.percentage_done}
          programFollowed={course && course.title}
          mail={!!count_messages}
          notification={!!count_notifications}
          reason={archived_reason ? reason_of_archived : ''}
          onClick={handleBeneficiaryClick}
          onNotificationsClick={handleNotificationsClick}
          onMessagesClick={handleMessagesClick}
          onDeleteClick={handleDeleteClick}
          nextAppointment={next_rdv}
          isDeletable={isPrimary}
          isManager={user.role === 'manager'}
          onAttributeClick={handleSelectBeneficiary}
          attributeLabelButton={'Attribuer'}
          attributeReadonly
          primaryConsultant={primaryConsultant}
          primaryConsultantLabel={'Consultant principal'}
          noPrimaryConsultantLabel={"Attention ce talent n'a pas de consultant actif"}
          primaryConsultantDeleted={!primaryConsultant || !!primaryConsultant.deleted_at}
          primaryConsultantName={
            primaryConsultant
              ? `${primaryConsultant.first_name} ${primaryConsultant.last_name}`
              : consultant
              ? `${consultant.first_name} ${consultant.last_name}`
              : 'Pas de consultant'
          }
        />
      );
    });
    // return <EmptyCard></EmptyCard>;
    return [...cards, <EmptyCard className="candidat-card" onClick={() => {
      window.location.href = '/consultant/beneficiaries/new';
    }} />] ;
  };

  return (
    <div className="beneficiary-list">
      {renderBeneficiaries()}
      {renderAttributePopin()}
    </div>
  );
};

BeneficiaryList.propTypes = {
  beneficiaries: PropTypes.array.isRequired,
  isPrimary: PropTypes.bool,
  onDeleteClick: PropTypes.func,
};

BeneficiaryList.defaultProps = {
  onDeleteClick: () => undefined,
  isPrimary: false,
};

export default BeneficiaryList;
