import React from "react";
import CircleButton from "../../../../atoms/CircleButton/CircleButton";
import './FeedbackRessource.scss';

export const FeedbackRessource = ({isExport = false, ressource = null}) => {
    console.log(ressource)
    return (
        <div>
            <div className="feedback-ressource">
                <p>{ressource.title}</p>
                <CircleButton icon={"edit"}/>
                
            </div>
            {isExport && 
            <div>
                <p>Consultant : {ressource.consultant_notes}</p>
                <p>Beneficiaire :{ressource.beneficiary_notes}</p>
                </div>
            }
        </div>
    )
}