import { axiosInstance } from "./baseApi";

export const addBeneficiaries = async(beneficiaries) => {
    const response = await axiosInstance.post('beneficiaries/add', beneficiaries);
    return response.data;
}

export const deleteBeneficiary = async(beneficiaries) => {
    const response = await axiosInstance.delete('beneficiaries/add', beneficiaries);
    return response.data;
}

export const changeConsultantPrimary = async (beneficiary_id, consultant_id) => {
    const response = await axiosInstance.put(`/beneficiaries/change_primary_consultant`, {beneficiary_id, consultant_id});
    return response.data;
}
export const updateBeneficiary = async (beneficiary) => {
    const response = await axiosInstance.put(`/beneficiaries/update`, beneficiary);
    return response.data;
}

// POST : /beneficiaries/add