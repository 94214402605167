/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
  PresentationLocale,
  genders,
  ContactLocale,
  educationOptions,
  situationOptions,
  activityOptions,
  socioProfressionalCategories,
} from './BeneficiaryProfileContentStates';

import PresentationCard from '../../../components/organisms/PresentationCard/PresentationCard';
import CoordCard from '../../../components/organisms/CoordCard/CoordCard';
import ExperienceCard from '../../../components/organisms/ExperienceCard/ExperienceCard';
import FileUpload from '../../global/FileUpload/FileUpload';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';
import { Toaster } from '../../../components/atoms/Toaster/Toaster';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as UserSelectors, actions as UserActions } from '../../../redux/UserRedux';
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux';
import {
  addBeneficiaries,
  changeConsultantPrimary,
  updateBeneficiary,
} from '../../../api/BeneficiaryAPI';
import { useToaster } from '../../../hooks/useToaster';
import moment from 'moment';
import { getAllConsultants, getConsultantInfos } from '../../../api/ConsultantApi';
import './BeneficiaryProfileContent.scss';
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux';
import { useLocation } from 'react-router-dom';
import BigCombo from '../../../components/molecules/BigCombo/BigCombo';
import { getUserProfile } from '../../../api/ProfileApi';
import { getUser } from '../../../api/userAPI';

export const BeneficiaryProfileContent = ({ userProps, readOnly, sendUser, isNew }) => {
  const u = useSelector(UserSelectors.user);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [userPresentation, setUserPresentation] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    image_url: '',
    source: '',
    birth_date: '',
  });
  const [userContact, setUserContact] = useState({
    address: '',
    zip_code: '',
    city: '',
    phone_mobile: '',
    phone_home: '',
    email: '',
  });
  const [userExperiences, setUserExperiences] = useState({
    cursus_company_activity_sector: '',
    cursus_cv_file: '',
    cursus_formation: '',
    diploma_name: '',
    cursus_function: '',
    cursus_handicap_file: '',
    cursus_handicap_status: '',
    cursus_professional_situation: '',
    cursus_socio_professional_category: '',
  });
  const [isConsultant, setIsConsultant] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [isAccountActivate, setIsAccountActivate] = useState();
  const { toasters, addToaster, removeToaster } = useToaster();
  const [consultant, setConsultant] = useState();
  const location = useLocation();
  const [consultants, setConsultants] = useState();

  useEffect(() => {
    if (u) {
      const consultantsTmp = getAllConsultants(u.office_id).then((response) => {
        const tmp = response.consultants.map((c) => {
          return { value: c.user.id, label: c.user.first_name + ' ' + c.user.last_name };
        });
        setConsultants(tmp);
      });

      if (u.role === 'beneficiary') {
        setUser(u);
      } else {
        setIsConsultant(true);
        setUser(userProps);
      }
    }
  }, [u]);

  useEffect(() => {
    if (user) {
      console.log(getPrimaryConsultant(user.consultants), user);
      setConsultant(getPrimaryConsultant(user.consultants));
      const { id, first_name, last_name, gender, image_url, source, birth_date, activated_at } =
        user;
      const date = moment(birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      const isValidDate = moment(date, 'DD/MM/YYYY').isValid();
      const { address, zip_code, city, phone_mobile, phone_home, email } = user;
      const {
        cursus_company_activity_sector,
        cursus_cv_file,
        cursus_formation,
        diploma_name,
        cursus_function,
        cursus_handicap_file,
        cursus_handicap_status,
        cursus_professional_situation,
        cursus_socio_professional_category,
      } = user;

      setUserPresentation({
        id: id,
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        image_url: image_url,
        source: source,
        birth_date: isValidDate ? date : '',
      });
      setUserContact({
        address: address,
        zip_code: zip_code,
        city: city,
        phone_mobile: phone_mobile,
        phone_home: phone_home,
        email: email,
      });
      setUserExperiences({
        cursus_company_activity_sector: cursus_company_activity_sector,
        cursus_cv_file: cursus_cv_file,
        cursus_formation: cursus_formation,
        diploma_name: diploma_name,
        cursus_function: cursus_function,
        cursus_handicap_file: cursus_handicap_file,
        cursus_handicap_status: cursus_handicap_status,
        cursus_professional_situation: cursus_professional_situation,
        cursus_socio_professional_category: cursus_socio_professional_category,
      });
      if (activated_at) {
        setIsAccountActivate(true);
      } else {
        setIsAccountActivate(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!disabledSaveButton) {
      location.state = { canChange: false };
    } else {
      location.state = { canChange: true };
    }
  }, [disabledSaveButton, location]);

  const handleOnSave = async () => {
    let updatedUserPresentation;

    if (user && user.id) {
      if (userPresentation.birth_date) {
        const birthday = moment(userPresentation.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        updatedUserPresentation = {
          ...userPresentation,
          birth_date: birthday,
        };
      } else {
        const birthday = null;
        updatedUserPresentation = {
          ...userPresentation,
          birth_date: birthday,
        };
      }
    } else {
      const { birth_date, ...restUserPresentation } = userPresentation;
      updatedUserPresentation = { ...restUserPresentation };
    }

    const new_user = {
      ...updatedUserPresentation,
      ...userContact,
      ...userExperiences,
      consultant_id: consultant.id,
    };
    if (!new_user?.id) {
      console.log(new_user);
      const new_user_objet = {
        email: new_user.email,
        first_name: new_user.first_name,
        last_name: new_user.last_name,
        image_url: new_user.image_url,
        consultant_id: consultant.consultant_id,
        gender: new_user.gender,
        accompaniment_type: 'user_collective_accompaniment',
        dispositif_type: 'antenna',
      };
      addBeneficiaries({ users: [new_user_objet] }).then((res) => {
        // window.location.href = "/manager/beneficiaries/me";
        console.log(res);
      });
    } else {
      if (u.role === 'manager') {
        const utmp = await getUser(user.id).then((res) => res.user);
        updateBeneficiary({
          ...updatedUserPresentation,
          ...userContact,
          ...userExperiences,
          user_id: user.id,
          status: user.status,
          source: new_user.source ?? '',
          image_url: new_user.image_url ?? '',
        }).then((res) => console.log(res));
      } else {
        const utmp = await getUser(user.id).then((res) => res.user);
        updateBeneficiary({
          ...updatedUserPresentation,
          ...userContact,
          ...userExperiences,
          user_id: user.id,
          status: user.status,
          source: new_user.source ?? '',
          image_url: new_user.image_url ?? '',
        }).then((res) => console.log(res));
      }
    }

    setDisabledSaveButton(true);
  };

  // methods for Presentation Container
  const handleChangePresentation = (newValue) => {
    const { id, value } = newValue;
    setUserPresentation({ ...userPresentation, [id]: value });

    const newUser = {
      ...userPresentation,
      ...userContact,
      ...userExperiences,
    };

    if (newUser) {
      sendUser(newUser);
    }

    setDisabledSaveButton(false);
  };

  const handleFileChange = ({ id, file }) => {
    setUserPresentation({ ...userPresentation, [id]: file.url });
  };

  // methods for Contact Container
  const handleChangeContact = (newValue) => {
    const { id, value } = newValue;
    setUserContact({ ...userContact, [id]: value });
    const newUser = {
      ...userPresentation,
      ...userContact,
      ...userExperiences,
    };

    sendUser(newUser);
    setDisabledSaveButton(false);
  };

  // methos for Experiences Container
  const handleChangeExperience = (newValue) => {
    const { id, value } = newValue;
    setUserExperiences({ ...userExperiences, [id]: value });
    const newUser = {
      ...userPresentation,
      ...userContact,
      ...userExperiences,
    };

    sendUser(newUser);
    setDisabledSaveButton(false);
  };

  const handleSendInvitation = () => {
    dispatch(UserActions.sendInvitation(user.id));
    addToaster('Invitation envoyée', "Un e-mail d'invitation a été envoyé au talent.", 'success');
  };

  // Render components
  const renderPresentation = () => {
    const { first_name, last_name, gender, image_url, source, birth_date } = userPresentation;

    const gendersList = genders.map((gender) => ({
      id: gender.key,
      value: gender.key,
      label: gender.label,
    }));

    return (
      <FileUpload accept="image/*" id="image_url" onChange={handleFileChange}>
        {({ onClick, uploading }) => (
          <PresentationCard
            title={isConsultant ? 'Présentation du talent' : 'Présentation'}
            photoUrl={image_url}
            gender={{ id: 'gender', value: gender, inputList: gendersList }}
            lastName={{ id: 'last_name', value: last_name }}
            firstName={{ id: 'first_name', value: first_name }}
            birth={{ id: 'birth_date', value: birth_date }}
            source={{ id: 'source', value: source }}
            locale={PresentationLocale}
            onChange={handleChangePresentation}
            onPictureClick={onClick}
            uploading={uploading}
            readOnly={readOnly}
            isNew={isNew}
          />
        )}
      </FileUpload>
    );
  };

  const renderContact = () => {
    const { address, zip_code, city, phone_mobile, phone_home, email } = userContact;

    return (
      <CoordCard
        title={isConsultant ? 'Coordonnées du talent' : 'Coordonnées'}
        address={{ id: 'address', value: address }}
        zip_code={{ id: 'zip_code', value: zip_code }}
        city={{ id: 'city', value: city }}
        phone_mobile={{ id: 'phone_mobile', value: phone_mobile }}
        email={{ id: 'email', value: email, readonly: !isConsultant }}
        phone_home={{ id: 'phone_home', value: phone_home }}
        locale={ContactLocale}
        readOnly={readOnly}
        onChange={handleChangeContact}
        isNew={isNew}
      />
    );
  };

  const renderExperiences = () => {
    const {
      cursus_formation,
      cursus_professional_situation,
      cursus_socio_professional_category,
      cursus_function,
      cursus_company_activity_sector,
      diploma_name,
    } = userExperiences;

    return (
      <ExperienceCard
        title={isConsultant ? 'Expériences du talent' : 'Expériences'}
        educationComboContent={{
          title: 'Niveau de formation *',
          id: 'cursus_formation',
          placeholder: 'Veuillez choisir votre niveau de formation',
          options: educationOptions,
          value: cursus_formation ? cursus_formation : '',
        }}
        situationComboContent={{
          title: 'Situation professionnelle *',
          id: 'cursus_professional_situation',
          placeholder: 'Veuillez choisir la situation professionnelle',
          options: situationOptions,
          value: cursus_professional_situation ? cursus_professional_situation : '',
        }}
        socioProfessionalCategoryComboContent={{
          title: 'Catégorie socioprofessionnelle *',
          id: 'cursus_socio_professional_category',
          placeholder: 'Veuillez choisir votre catégorie socioprofessionnelle',
          options: socioProfressionalCategories,
          value: cursus_socio_professional_category ? cursus_socio_professional_category : '',
          required: false,
        }}
        diplomaInputContent={{
          inputTitle: 'Titre du diplôme',
          placeholder: '',
          inputName: 'diploma_name',
          inputValue: diploma_name ? diploma_name : '',
        }}
        functionInputContent={{
          inputTitle: 'Fonctions *',
          placeholder: '',
          inputName: 'cursus_function',
          inputValue: cursus_function ? cursus_function : '',
        }}
        activityComboContent={{
          title: "Secteur d'activité",
          id: 'cursus_company_activity_sector',
          placeholder: "Veuillez choisir le secteur d'activité",
          options: activityOptions,
          value: cursus_company_activity_sector ? cursus_company_activity_sector : '',
          required: false,
        }}
        onChange={handleChangeExperience}
        readOnly={readOnly}
      />
    );
  };

  const handleChangeConsultant = async (value) => {
    console.log(user);
    if (user && user?.id) {
      const { beneficiary_id } = await getUser(user.id).then((res) => res.user);
      console.log(beneficiary_id);
      if (value.value.value) {
        getConsultantInfos(value.value.value).then((res) => {
          changeConsultantPrimary(beneficiary_id, res.consultant.id);
          setConsultant(res.consultant.user);
        });
      }
    } else {
      if (value.value.value) {
        getConsultantInfos(value.value.value).then((res) => {
          setConsultant(res.consultant.user);
        });
      }
    }
  };

  const getPrimaryConsultant = (consultants) => {
    if (consultants) {
      return consultants.find((c) => c.is_primary === true) || null;
    }
    return null;
  };

  const renderConsultant = () => {
    console.log(userProps);
    console.log(consultant);
    return (
      <div className="coord-card">
        <div className="icon-card-title">
          {' '}
          <h1 className="label">Consultant {consultant?.first_name}</h1>
        </div>
        <BigCombo
          options={consultants}
          value={{
            label: consultant?.first_name + ' ' + consultant?.last_name,
            value: consultant?.id,
          }}
          onChange={handleChangeConsultant}
        />
      </div>
    );
  };

  return (
    <div className="beneficiary-profile-content">
      {/* commentaires du manager */}

      <div className="beneficiary-profile-content-save">
        <PrimaryButton
          id="edit-beneficiary-profile-content"
          label={'Sauvegarder'}
          onClick={handleOnSave}
          disabled={disabledSaveButton}
        />
      </div>
      {!isNew && !isAccountActivate && (
        <div className="beneficiary-account-not-active">
          <p>Le talent n'a pas encore activé son compte.</p>
          <PrimaryButton label={"Renvoyer l'invitation"} onClick={handleSendInvitation} />
        </div>
      )}
      <div className="beneficiary-profile-content-container">
        {renderPresentation()}
        {renderContact()}
        {u.role === 'manager' && renderConsultant()}
        {!isNew && renderExperiences()}
      </div>

      <div className="notification-container">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};
