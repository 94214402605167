/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../../redux/CourseTemplateRedux';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux';
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../../redux/BeneficiaryRedux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import Icon from '../../../components/atoms/Icon/Icon';
import ActionPopinHeader from '../../../components/molecules/ActionPopinHeader/ActionPopinHeader';
import PopinLayout from '../../../layouts/PopinLayout/PopinLayout';
import RightAsideLayout from '../../../layouts/RightAsideLayout/RightAsideLayout';
import TabbedContentLayout from '../../../layouts/TabbedContentLayout/TabbedContentLayout';
import LineMenu from '../../../components/organisms/LineMenu/LineMenu';
import ComboField from '../../../components/molecules/ComboField/ComboField';
import CircleButton from '../../../components/atoms/CircleButton/CircleButton';
import CourseEditDragDropContext from '../../course/CourseEditDragDropContext/CourseEditDragDropContext';
import BeneficiaryArchivedReasonPopin from '../BeneficiaryArchivedReasonPopin/BeneficiaryArchivedReasonPopin';
import ConsultantShareBeneficiary from '../ConsultantShareBeneficiary/ConsultantShareBeneficiary';
import AvatarCircle from '../../../components/atoms/AvatarCircle/AvatarCircle';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './ConsultantBeneficiary.scss';
import { DontLeavePopin } from '../../popins/DontLeavePopin/DontLeavePopin';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  cvs: BeneficiarySelectors.cvs(state),
  maestros: BeneficiarySelectors.maestros(state),
  template: CourseTemplateSelectors.template(state),
});

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  setBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.setBeneficiary(beneficiary)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  onBack: (disableSave) => dispatch(BeneficiaryActions.onBack(disableSave)),
  setTemplateSteps: (steps) => dispatch(CourseTemplateActions.setTemplateSteps(steps)),
  setTemplate: (template) => dispatch(CourseTemplateActions.setTemplate(template)),
});

const ConsultantBeneficiary = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSharingPopin, setOpenSharingPopin] = useState(false);
  const [openArchivedPopin, setOpenArchivedPopin] = useState(false);
  const [unblock, setUnblock] = useState(null);
  const [backIsClicked, setBackIsClicked] = useState(false);
  const [talent, setTalent] = useState({});
  const [showBurger, setShowBurger] = useState(false);
  const location = useLocation();
  const [showDontQuitPopin, setShowDontQuitPopin] = useState(false);
  const [idTab, setIdTab] = useState();

  const handleBack = () => {
    console.log(history.location.pathname.split('/beneficiaries/'))
    console.log(window.location);
    if (props.user.role === 'manager') {
      console.log(history.location.pathname.split('/beneficiaries/'))
      if (history.location.pathname.split('/beneficiaries/')[0].includes('consultants')) {
        window.location.href = history.location.pathname.split('/beneficiaries/')[0];
        // history.push(history.location.pathname.split('/beneficiaries/')[0]);
      } else {
        window.location.href = '/manager/beneficiaries/me';
        // history.push('/manager/beneficiaries/me');
      }
    } else if (document.referrer && document.referrer.includes('consultant/beneficiaries/')) {
      history.push('/consultant/beneficiaries/me');
    } else {
      window.location.href = document.referrer;
    }
  };
  useEffect(() => {
    console.log(window.location.pathname)
  }, []);

  // useEffect(() => {
  //   const unblock = history.block(() => {
  //     // console.log('block');
  //     if (backIsClicked && !props.disableSave) {
  //       return 'true';
  //     }
  //   });

  //   setUnblock(unblock);
  // }, []);

  const isPrimary = () => {
    const { user, beneficiary } = props;

    if (!beneficiary.id) {
      return true;
    }

    if (user.role === 'manager') {
      return true;
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    );
  };

  const getArchivedReasonsOptions = () => {
    const beneficiaryArchivedReasons = [
      { key: 'completed', value: 'Accompagnement terminé' },
      { key: 'beneficiary_abandonment', value: 'Abandon du talent' },
      { key: 'budget_not_accepted', value: 'Budget non accepté' },
      { key: 'beneficiary_refusal', value: 'Refus talent' },
      { key: 'other', value: 'Autre' },
    ];

    return beneficiaryArchivedReasons.map((item) => ({
      value: item.key,
      label: item.value,
    }));
  };

  const handleBeneficiaryArchivedReasonChange = ({ value }) => {
    const newBeneficiary = {
      ...props.beneficiary,
      archived_reason: value,
    };

    props.setBeneficiary(newBeneficiary);
  };

  const handleShowBurger = () => {
    setShowBurger(!showBurger);
    if (showBurger) {
      document.getElementById('consultant-beneficiary-burger-menu').style.left = '-100%';
    } else {
      document.getElementById('consultant-beneficiary-burger-menu').style.left = '0';
    }
  };
  const handleBeneficiaryStatusChange = ({ value }) => {
    let archivedReason = {};
    if (value === 'archived') {
      archivedReason = {
        archived_reason: getArchivedReasonsOptions()[0].value,
      };

      setOpenArchivedPopin(true);
    }

    const newBeneficiary = {
      ...props.beneficiary,
      status: value,
      ...archivedReason,
    };
    setTalent(newBeneficiary);
    props.setBeneficiary(newBeneficiary);
    dispatch(BeneficiaryActions.saveBeneficiary(newBeneficiary));
  };

  const handleCloseArchivedReasonPopin = () => {
    setOpenArchivedPopin(false);
  };

  const handleOrderModulesChange = (index, modules) => {
    const { template } = props;
    const steps = template.steps.slice(0);

    steps[index] = { ...steps[index], modules };

    props.setTemplateSteps(steps);
  };

  const handleOrderStepsChange = (steps) => {
    props.setTemplateSteps(steps);
  };

  const handleSave = () => {
    props.onSave();
  };

  const handleToggleSharingPopin = (open = null) => {
    let newOpenSharingPopin = open;
    if (newOpenSharingPopin === null) {
      newOpenSharingPopin = !openSharingPopin;
    }
    setOpenSharingPopin(newOpenSharingPopin);
  };

  const handleTabChange = (tabId) => {
    const { pathname, redirect } = props;

    setIdTab(tabId);
    if (location && location.state && !location.state.canChange) {
      setShowDontQuitPopin(true);
    } else {
      if (/\/courses/.test(pathname) && tabId !== 'courses') {
        redirect(pathname.replace(/(\/courses.*)/, `/${tabId}`));
        return;
      }
      let current = pathname.substring(pathname.lastIndexOf('/') + 1);
      if (tabId !== current) {
        redirect(pathname.replace(`/${current}`, `/${tabId}`));
      }
    }
  };

  const handleConfirmTabChange = () => {
    const { pathname, redirect } = props;

    if (/\/courses/.test(pathname) && idTab !== 'courses') {
      redirect(pathname.replace(/(\/courses.*)/, `/${idTab}`));
      return;
    }
    let current = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (idTab !== current) {
      redirect(pathname.replace(`/${current}`, `/${idTab}`));
    }
  };

  const renderContent = () => {
    const { children } = props;

    return <TabbedContentLayout header={renderTabs()} content={children} />;
  };

  const renderHeader = () => {
    const { beneficiary, disableSave, pathname } = props;
    const { status, first_name, last_name } = beneficiary;

    const beneficiaryStatus = status || '';

    const statuses = [
      { key: '', value: 'Tous' },
      { key: 'created', value: 'Prospect' },
      { key: 'wait_financer', value: 'En attente de financement' },
      { key: 'in_validation', value: 'En attente de validation' },
      { key: 'in_progress', value: 'En cours' },
      { key: 'follow_up', value: 'Suivi professionel' },
      { key: 'archived', value: 'Historique' },
    ];
    const options = statuses.map((item) => ({
      value: item.key,
      label: item.value,
    }));
    const { renderAside } = props;

    return (
      <>
        {
          <div id="consultant-beneficiary-burger-menu" className="action-mobile-burger">
            <ComboField
              name="status"
              title={'Etat actuel :'}
              options={options}
              onChange={handleBeneficiaryStatusChange}
              value={beneficiaryStatus || 'created'}
              disabled={!isPrimary() || beneficiaryStatus === '' || /\/courses/.test(pathname)}
            />
            {renderAside && renderAside()}
          </div>
        }
        <ActionPopinHeader
          iconButton={Icon.icon.Back}
          lightTitle={beneficiaryStatus !== '' ? 'Talent' : 'Nouveau talent'}
          boldTitle={beneficiaryStatus !== '' ? `${first_name} ${last_name}` : ''}
          onIconClick={handleBack}
          burgerMenu={
            <>
              <div
                type="button"
                className="resources-btn resources-burger"
                onClick={handleShowBurger}
              >
                <Icon icon={Icon.icon.BurgerMenu} color={Icon.color.Accent} />
              </div>
            </>
          }
        >
          <div className="statuses_combo">
            <ComboField
              name="status"
              title={'Etat actuel :'}
              options={options}
              onChange={handleBeneficiaryStatusChange}
              value={beneficiaryStatus || 'created'}
              disabled={!isPrimary() || beneficiaryStatus === '' || /\/courses/.test(pathname)}
            />
          </div>
        </ActionPopinHeader>
      </>
    );
  };

  const renderShareComponent = () => {
    const { user, beneficiary } = props;

    if (!isPrimary() || user.role === 'manager') {
      return null;
    }

    if (!beneficiary?.id) {
      return null;
    }

    return (
      <div className="share-container">
        {renderSharedConsultants()}
        <CircleButton icon={Icon.icon.Share} onClick={handleToggleSharingPopin} />
      </div>
    );
  };

  const renderSharedConsultants = () => {
    const { beneficiary } = props;

    if (!beneficiary.id) {
      return null;
    }

    const consultantsNodes = beneficiary.consultants.map((consultant, i) => (
      <div key={i} className="avatar-wrapper" style={{ zIndex: i }}>
        <AvatarCircle photoUrl={consultant.image_url} />
      </div>
    ));

    return <div className="consultants">{consultantsNodes}</div>;
  };

  const renderSharingPopin = () => {
    const open = !!(openSharingPopin && props.beneficiary);

    return (
      <ConsultantShareBeneficiary open={open} onClose={() => handleToggleSharingPopin(false)} />
    );
  };

  const renderArchivedReasonPopin = () => {
    return (
      <BeneficiaryArchivedReasonPopin
        onClose={handleCloseArchivedReasonPopin}
        open={openArchivedPopin}
        onReasonChange={handleBeneficiaryArchivedReasonChange}
        onValidate={handleSave}
        reasonsOptions={getArchivedReasonsOptions()}
      />
    );
  };

  const renderTabs = () => {
    const { pathname, beneficiary, cvs, maestros } = props;

    if (!beneficiary?.id) {
      return null;
    }

    let links = [
      {
        id: 'courses',
        label: 'Parcours Suivi',
      },
      {
        id: 'profile',
        label: 'Informations personnelles',
        sublabel: `Dernière édition : ${moment(beneficiary.updated_at).format('DD/MM/YYYY HH:MM')}`,
      },
      {
        id: 'accompaniment',
        label: 'Accompagnement',
      },

      // {
      //   id: 'assessments',
      //   label: 'Central Test',
      // },
    ];

    // if (cvs && cvs.length > 0) {
    links.push({
      id: 'cvs',
      label: 'CV',
    });
    // }

    // if (maestros && maestros.length > 0) {
    links.push({
      id: 'maestros',
      label: 'Candidatures',
    });
    // }

    links.push({
      id: 'calendar',
      label: 'Agenda',
    });

    let current = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (/\/courses/.test(pathname)) {
      current = 'courses';
    }

    return (
      <LineMenu
        value={current}
        links={links}
        onChange={handleTabChange}
        components={renderShareComponent()}
        withSublabel
      />
    );
  };

  const { renderAside } = props;
  const header = renderHeader();

  const content = (
    <CourseEditDragDropContext
      onChangeSteps={handleOrderStepsChange}
      onChangeModules={handleOrderModulesChange}
    >
      <RightAsideLayout content={renderContent()} aside={renderAside && renderAside()} />
    </CourseEditDragDropContext>
  );

  return (
    <div className="consultant-beneficiary">
      <PopinLayout header={header} content={content} />
      {renderSharingPopin()}
      {renderArchivedReasonPopin()}
      {showDontQuitPopin && (
        <DontLeavePopin
          open={showDontQuitPopin}
          onClose={() => setShowDontQuitPopin(false)}
          handleActionButton={handleConfirmTabChange}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantBeneficiary);
