// Main accompaniment

const AccompanimentTypeLocale = {
    accompanimentTypeLabel: "Type d'accompagnement",
    groupLabel: "Groupe",
    homeOfficeLabel: "Bureau de rattachement",
    durationLabel: "Durée",
    synchronousTimeLabel: "Durée synchrone",
    asynchronousTimeLabel: "Durée asynchrone",
    termsLabel: "Modalités",
    vaeAvailabilityLabel: "Disponibilité pour la VAE",
    vaeEligibilityDateLabel: "Date de recevabilité",
    vaeBookletDateLabel: "Date de rendu du livret 2",
    startDateLabel: "Date de début",
    endDateLabel: "Date de fin",
    bilanTypeLabel: "Type de bilan",
    bilanAvailabilityLabel: "Disponibilité pour le bilan",
    dayShortLabels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    accompanimentSurveyCountLabel: "Nombre d'enquête métier",
    accompanimentSurveyTypeLabel: "Nature de l'enquête metier",
    otherAccompanimentLabel: "Nom de l'accompagnement",
    accompanimentCollectiveTypeLabel: "Type de dispositif",
    HandicapAmendmentRequirementLabel: "Situation de handicap qui nécessite un aménagement",
    dateSixMonthsLabel: "Date du RDV à 6 mois",
    firstAppointmentDateLabel: "Date 1er RDV d'information",
    situationFollowUpLabel: "Suivi de situation",
    formationLabel: "Formation",
    bilanFranceTravailIdLabel: "Identifiant France travail",
    globalTimeLabel: "Durée globale",
    formationTitleLabel: "Titre de la formation",
}

const accompanimentTypes = [
    { value: "appraisal", label: "Bilan de compétences", object: "user_appraisal_accompaniment" },
    { value: "outplacement_individual", label: "Outplacement Individuel", object: "outplacement_individual_accompaniment" },
    { value: "vae", label: "VAE", object: "user_VAE_accompaniment" },
    { value: "coaching", label: "Coaching", object: "coaching_accompaniment" },
    { value: "collective_accompaniment", label: "Accompagnement collectif", object: "user_collective_accompaniment" },
    { value: "orientation_review", label: "Bilan orientation", object: "user_orientation_review" },
    { value: "business_creation", label: "Création d'entreprise", object: "business_creation_accompaniment" },
    { value: "collective_scheme", label: "Dispositif collectif", object: "collectif_scheme_accompaniment" },
    { value: "formation", label: "Formation", object: "formation_accompaniment" },
    { value: "other", label: "Autre", object: "other_accompaniment" },
]

const accompanimentDurationTypes = [
    { value: "hours", label: "Heures" },
    { value: "months", label: "Mois" },
    { value: "days", label: "Jours" },
]

const appraisalTypes = [
    { value: " ", label: "Aucun" },
    { value: "pole_emploi", label: "France travail" },
    { value: "formation_plan", label: "Plan de développement des compétences" },
    { value: "leave_of_absence", label: "Financement personnel" },
    { value: "cpf", label: "CPF" },
    { value: "individual", label: "Particulier" },
    { value: "other", label: "Autre" },

]

const appraisalAvailabilities = [
    { value: " ", label: "Aucun" },
    { value: "within_work", label: "Durant le travail" },
    { value: "out_of_work", label: "Hors horaires de travail" },
]

const vaeAvailabilities = [
    { value: " ", label: "Aucun" },
    { value: "within_work", label: "Durant le travail" },
    { value: "out_of_work", label: "Hors horaires de travail" },
]

const typeCollectiveAccompaniment = [
    { value: "reclassification_leave", label: "Congé de reclassement" },
    { value: "professional_securitization_contract", label: "Contrat de sécurisation professionnel (CSP)" },
    { value: "mobility_leave", label: "Congé de mobilité" },
    { value: "gepp", label: "GEPP" },
    { value: "pic_eic", label: "PIC/EIC" },
    { value: "antenna", label: "Antenne" },
    { value: "internal_mobility", label: "Mobilité interne" },
]

const termsList = [
    { value: " ", label: "Aucun" },
    { value: 'face_to_face', label: "Présentiel" },
    { value: 'remote', label: "Distanciel" },
    { value: 'mixt', label: "Mixte" },
]

const situationFollowUpList = [
    { value: " ", label: "Aucun" },
    { value: 'training_or_training_project', label: "En formation ou projet de formation" },
    { value: 'apprenticeship', label: "En alternance" },
    { value: 'internal_new_job', label: "Nouvel emploi interne" },
    { value: 'external_new_job', label: "Nouvel emploi externe" },
    { value: 'business_creation_or_takeover', label: "Création ou reprise d’entreprise" },
    { value: 'VAE_or_VAE_project', label: "En VAE ou projet VAE" },
    { value: 'job_search', label: "En recherche d’emploi" },
    { value: 'current_position_kept', label: "Poste actuel conservé" },
    { value: 'other', label: "Autre" }
]

const formationList = [
    { value: ' ', label: "Aucun" },
    { value: 'intra', label: "Intra" },
    { value: 'inter', label: "Inter" },
]

export {
    AccompanimentTypeLocale,
    accompanimentTypes,
    accompanimentDurationTypes,
    appraisalTypes,
    appraisalAvailabilities,
    vaeAvailabilities,
    typeCollectiveAccompaniment,
    termsList,
    situationFollowUpList,
    formationList,
};